@import 'styles/theme.scss';

.outsideContainer {
  margin: 0;
  @media (min-width: $md) {
    margin: 0 #{$spacing * 3};
  }
}
.childrenContainer {
  width: 100%;
  padding: 0 24px;
  @media (min-width: $md) {
    width: 924px;
    margin: 0 auto;
    padding: 0;
  }
}
